 <template>
  <MglMap :accessToken="accessToken" 
          :mapStyle="mapStyle"
              @load="onMapLoaded"
       />
</template>
<script>
 
import 'mapbox-gl/dist/mapbox-gl.css';
const ACCESS_TOKEN = 'pk.eyJ1IjoiZG9sYXRvZ3luIiwiYSI6ImNrdTB4d2t3OTBqemsycnQ5Z3Y1dm9udWcifQ.0FMtr2fsbh5BhhTBgbweeQ'
const MAP_STYLE = 'mapbox://styles/mapbox/streets-v11' 

 
import Mapbox from "mapbox-gl";
import { MglMap } from "vue-mapbox";

export default {
  components: {
    MglMap
  },
  data() {
    return {
      accessToken: ACCESS_TOKEN,
      mapStyle: MAP_STYLE,
      marker : null,
      mapbox : null
    };
  },
  props : {
      coordinates : { type : Array, default : () => [0,0]}
  },
  created(){
      this.mapbox = Mapbox;
  },
  methods : {
       async  onMapLoaded(event) {      
         // Here we cathing 'load' map event
      // const asyncActions = event.component.actions

      // const newParams = await asyncActions.flyTo({
      //   center: this.coordinates,
      //   zoom: 15,
      //   speed: 1
      // })
      //console.warn(newParams)
    }
  }
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 150px;
}
.mgl-map-wrapper{
  width: 100%;
  height: 150px;
}
</style>