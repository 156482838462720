var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MglMap", {
    attrs: { accessToken: _vm.accessToken, mapStyle: _vm.mapStyle },
    on: { load: _vm.onMapLoaded }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }